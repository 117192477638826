<template>
  <data-view-layout>
    <div slot="left-pane" class="left-pane">
      <!-- 左上表格：年月日产量 -->
      <data-view-card
        style="width:100%;height:20.93%;"
        v-loading="left1.loading">
        <resize-wrap class="h-100-p">
          <template v-slot="{height}">
            <scroll-table
              v-if="height"
              :height="height"
              :data="left1.data"
              :titles="left1.titles"
            ></scroll-table>
          </template>
        </resize-wrap>
      </data-view-card>

      <!-- 左2：产能效率 -->
      <data-view-card
        class="flex justify-around align-center mt-11 p-15"
        style="width:100%;height:20.93%;"
        v-loading="left2.loading">
        <chart-pie-warn
          style="height:100%;width:30%;"
          v-bind="item"
          v-for="item in left2.productivityEfficiencys"
          :key="item.name"
        />
        <div class="ft-12" style="font-size:12px" v-if="left2.productivityEfficiencys.length<=0">暂无数据</div>
      </data-view-card>

      <!-- 左3 -->
      <data-view-card
        class="mt-10"
        style="width:100%;height:58.13%;"
        v-loading="left3.loading"
      >
        <chart-bar
          style="width:100%;height:33.33%;"
          v-for="(item, index) in left3.dataCompareDtoList"
          :key="item.type"
          :title="item.type"
          :unit="left3.units[index]"
          :data="item"
        />
      </data-view-card>
    </div>

    <div slot="middle-pane" class="middle-pane">
      <!-- 中上地图 type：0总览，1商砼，2砂浆，3水泥，0车辆 -->
      <data-view-map
        :type="1"
        style="width:100%;height:52.81%;"
        :totals="midTotals"
        @area-change="areaChange"
      />

      <!-- 正中间表格: 区年月日产量 -->
      <data-view-card
        class="mt-11"
        style="width:100%;height:45.85%;position:relative;"
        v-loading="mid2.loading"
      >
        <div class="table-cate-bar">
          <div
            class="cate-item"
            @click="selectedCate(key)"
            v-for="({name, key}) in mid2.tabs"
            :key="key"
            :class="{active: mid2ActKey == key}"
          >{{name}}</div>
        </div>

        <resize-wrap class="h-100-p">
          <template v-slot="{height}">
            <scroll-table
              v-if="height"
              :height="height"
              :key="mid2ActKey"
              :data="mid2[mid2ActKey].data"
              :titles="mid2[mid2ActKey].title"
            ></scroll-table>
          </template>
        </resize-wrap>
      </data-view-card>
    </div>

    <div slot="right-pane" class="right-pane">
      <data-view-card
        class="mb-11"
        style="width:100%;height:20.93%;position:relative;"
        v-loading="right1.loading4"
      >
        <resize-wrap class="h-100-p">
          <template v-slot="{height}">
            <scroll-table
              v-if="height"
              :height="height"
              :data="right1.data"
              :titles="right1.titles"
            ></scroll-table>
          </template>
        </resize-wrap>
      </data-view-card>

      <data-view-card
        class="flex mb-10 justify-around"
        style="width:100%;height:20.93%;"
        v-loading="right2.loading"
      >
        <chart-pie2
          style="width:33.33%;height:100%;"
          v-for="(item, index) in right2.pies"
          :key="index"
          :data="item"
          :color="right2.colors[index]"
        />
      </data-view-card>

      <data-view-card
        style="width:100%;height:58.13%;"
        v-loading="right3.loading"
      >
        <chart-line
          style="width:100%;height:33.33%;"
          v-for="(item,index) in right3.linesData"
          :key="index"
          :title="item.title"
          :label="right3.labels[index]"
          :unit="right3.units[index]"
          :data="item"
        />
      </data-view-card>
    </div>
  </data-view-layout>
</template>

<script>
import { ChartLine, ChartBar, ChartPie2, ChartPieWarn } from '@/components/Charts'
import { DataViewLayout, DataViewCard } from '@/components/DataView'
import {
  getUpleProductionValueByType,
  getCompareMonthUpleData,
  getProductionVehiTable,
  getProductionVehiData,
  getConcreteYearProjData,
  getProductionTypeRateRankData,
  getUpleProductionValueDate
} from '@/apis/dataView'
import ScrollTable from '@/components/ScrollTable'
import DataViewMap from './components/DataViewMap'
import XEUtils from 'xe-utils'

export default {
  data () {
    return {
      type: 1,
      left1: {
        loading: false,
        titles: [
          { name: '区域名称', prop: 'productionName' },
          { name: '日产量(方)', prop: 'dayWeight' },
          { name: '月产量(方)', prop: 'monthWeight' },
          { name: '年产量(方)', prop: 'yearWeight' },
          { name: '年产量占比(%)', prop: 'yearWeightPercent' }
        ],
        data: []
      },
      left2: {
        loading: false,
        productivityEfficiencys: []
      },
      left3: {
        loading: false,
        dataCompareDtoList: [
          { type: '混凝土产能效率', xAxis: [], yAxis: [] },
          { type: '混凝土月产量对比', xAxis: [], yAxis: [] },
          { type: '产品标号产量分布', xAxis: [], yAxis: [] }
        ],
        units: ['%', '方', '方']
      },
      mid2ActKey: 'dayWeight',
      mid2: {
        loading: false,
        tabs: [
          { key: 'dayWeight', name: '日产量' },
          { key: 'monthWeight', name: '月产量' },
          { key: 'yearWeight', name: '年产量' },
          { key: 'efficiency', name: '产能效率' }
        ],
        dayWeight: {
          title: [
            { name: '排名', prop: 'rank' },
            { name: '企业名称', prop: 'name' },
            { name: '产量(方)', prop: 'dayWeight' },
            { name: '发货量(方)', prop: 'shipWeight' }
          ],
          data: []
        },
        monthWeight: {
          title: [
            { name: '排名', prop: 'rank' },
            { name: '企业名称', prop: 'name' },
            { name: '产量(方)', prop: 'dayWeight' },
            { name: '发货量(方)', prop: 'shipWeight' }
          ],
          data: []
        },
        yearWeight: {
          title: [
            { name: '排名', prop: 'rank' },
            { name: '企业名称', prop: 'name' },
            { name: '产量(方)', prop: 'dayWeight' },
            { name: '发货量(方)', prop: 'shipWeight' }
          ],
          data: []
        },
        efficiency: {
          title: [
            { name: '排名', prop: 'rank' },
            { name: '企业名称', prop: 'name' },
            { name: '产能效率(%)', prop: 'efficiency' }
          ],
          data: []
        }
      },
      midTotals: [
        { name: '企业总数', value: 0, prop: 'compCount' },
        { name: '生产线总数', value: 0, prop: 'queuCount' },
        { name: '车辆总数', value: 0, prop: 'vehiCount' },
        { name: '车辆上线总数', value: 0, prop: 'vehiOnlineCount' },
        { name: '报警总数', value: 0, prop: 'warnCount' }
      ],
      right1: {
        loading: false,
        titles: [
          { name: '区域名称', prop: 'name' },
          { name: '前年重点项目数(个)', prop: 'lastTwoYearCount' },
          { name: '去年重点项目数(个)', prop: 'lastYearCount' },
          { name: '今年重点项目数(个)', prop: 'thisYearCount' }
        ],
        data: []
      },
      right2: {
        loading: false,
        pies: [
          [
            { name: '', value: 0 },
            { name: '', value: 100 }
          ],
          [
            { name: '', value: 0 },
            { name: '', value: 100 }
          ],
          [
            { name: '', value: 0 },
            { name: '', value: 100 }
          ]
        ],
        colors: ['#DA0051', '#FFB508', '#01BD8D']
      },
      right3: {
        loading: false,
        linesData: [
          { title: '', unit: '%', xAxis: [], yAxis: [] },
          { title: '', unit: '次', xAxis: [], yAxis: [] },
          { title: '', unit: '次', xAxis: [], yAxis: [] }
        ],
        units: ['%', '次', '次'],
        labels: ['上线率', '超速次数', '报警次数']
      }
    }
  },
  methods: {
    areaChange (params) {
      this.getUpleProductionValueByType(params)
      this.getTotalDayProductionValueByUple(params)
      this.getCompareMonthUpleData(params)
      this.initRightData(params)
      this.initProductionVehiData(params)
      this.getConcreteYearProjData(params)
      this.getProductionTypeRateRankData(params)
    },
    // 左1， 日月年产量
    async getUpleProductionValueByType (params) {
      const left1 = this.left1
      left1.loading = true
      // type： 1 混凝土， 2 砂浆
      const res = await getUpleProductionValueByType({ ...params, type: 1 })
      left1.loading = false
      if (res.code == 200) {
        left1.data = res.data.productIndexReptStatisticsDtoList
      } else {
        this.$message.error(res.msg)
      }
    },
    // 左3
    async getCompareMonthUpleData (params) {
      const left3 = this.left3
      const type = this.type
      left3.loading = true
      // type： 1 混凝土， 2 砂浆
      const res = await getCompareMonthUpleData({ ...params, type })
      left3.loading = false
      if (res.code == 200) {
        const { dataCompareDtoList } = res.data
        left3.dataCompareDtoList = dataCompareDtoList.map(({
          type,
          lineList: xAxis,
          columnList: yAxis
        }) => {
          return { type, xAxis, yAxis }
        })
      } else {
        this.$message.error(res.msg)
      }
    },
    // 中2 发生改变
    selectedCate (id) {
      if (this.mid2ActKey != id) {
        this.mid2ActKey = id
      }
    },
    // 中2
    async getMid2 (key, params) {
      const dateTypes = { dayWeight: 1, monthWeight: 2, yearWeight: 3 }
      const mid2 = this.mid2
      const type = this.type
      mid2.loading = true
      const res = await getUpleProductionValueDate({ ...params, type, dateType: dateTypes[key] })
      mid2.loading = false
      if (res.code == 200) {
        mid2[key].data = (res.data.dayWeightRankList.map((item, index) => {
          item.rank = index + 1
          return item
        })) || []
      } else {
        this.$message.error(res.msg)
      }
    },
    // 中2
    getTotalDayProductionValueByUple (params) {
      this.getMid2('dayWeight', params)
      this.getMid2('monthWeight', params)
      this.getMid2('yearWeight', params)
    },
    // left2 + mid2获取产能效率
    async getProductionTypeRateRankData (params) {
      const mid2 = this.mid2
      const left2 = this.left2
      mid2.loading = true
      left2.loading = true
      const res = await getProductionTypeRateRankData(params)
      mid2.loading = false
      left2.loading = false
      if (res.code == 200) {
        // mid2：产量效率排名
        const efficiencyRankList = res.data.efficiencyRankList || []
        mid2.efficiency.data = (efficiencyRankList.map((item, index) => {
          item.rank = index + 1
          return item
        })) || []
        // left2：产能效率饼图
        left2.productivityEfficiencys = efficiencyRankList.slice(0, 3).map(({ name, efficiency: value }) => {
          return { name, value }
        })
      } else {
        this.$message.error(res.msg)
      }
    },

    async initRightData (params = {}) {
      params.type = 1
      const { right3 } = this
      right3.loading = true
      const res = await getProductionVehiTable(params)
      right3.loading = false
      if (res.code == 200) {
        const data = res.data || {}
        const dataCompareDtoList = data.dataCompareDtoList || []
        right3.linesData = dataCompareDtoList.map((item) => {
          const titles = { 5: '近五年重点项目数', 6: '车辆超速次数', 7: '车辆报警次数', 8: '车辆上线率' }
          return {
            xAxis: item.lineList,
            yAxis: item.columnList,
            title: titles[item.type]
          }
        })
      } else {
        this.$message.error(res.msg)
      }
    },
    // 右2+total
    async initProductionVehiData (params = {}) {
      params.type = 1
      const { right2 } = this
      right2.loading = true
      const res = await getProductionVehiData(params)
      right2.loading = false
      if (res.code == 200) {
        const data = res.data || {}
        const shareRateDtoList = data.shareRateDtoList || []
        this.midTotals = this.midTotals.map(item => {
          const value = data[item.prop] || 0
          item.value = value
          return item
        })
        right2.pies = shareRateDtoList.map(item => {
          return [
            { name: item.shareDesc, value: item.sharePercent },
            { name: item.deShareDesc, value: XEUtils.round(100 - item.sharePercent, 2) }
          ]
        })
      } else {
        this.$message.error(res.msg)
      }
    },
    async getConcreteYearProjData (params) {
      const { right1 } = this
      right1.loading = true
      const res = await getConcreteYearProjData(params)
      right1.loading = false
      if (res.code == 200) {
        const data = res.data || {}
        const areaProjCountList = data.areaProjCountList || []
        right1.data = areaProjCountList
      } else {
        this.$message.error(res.msg)
      }
    }
  },
  components: {
    DataViewLayout,
    DataViewCard,
    ChartLine,
    ChartBar,
    ChartPie2,
    ChartPieWarn,
    ScrollTable,
    DataViewMap
  }
}
</script>

<style lang="scss" scoped>
.middle-pane {
  width: calc(41.24% - 22px);
  margin: 0 11px;
  height: 100%;
  position: relative;
}
.left-pane,
.right-pane {
  box-sizing: border-box;
  color: #fff;
  width: 29.38%;
  height: 100%;
  padding-bottom: 21px;
}
.table-cate-bar {
  position: absolute;
  top: -22px;
  display: flex;
  .cate-item {
    width: 60px;
    line-height: 22px;
    background: #134F54;
    font-size: 12px;
    text-align: center;
    color: #FFFFFF;
    margin-right: 10px;
    cursor: pointer;

    &.active {
      background: #059F55;
    }
  }
}
::v-deep {
  .el-loading-mask {
    background: rgba(0, 0, 0, .45);
  }
  .el-loading-spinner .path{
    stroke: #26FFF8!important;
  }
}
</style>
